import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { UserContext } from './user.context';

export const PrinterContext = createContext();

export const usePrinter = () => {
  return useContext(PrinterContext);
};

export const PrinterProvider = ({ children }) => {
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [errorOccurred, setErrorOccurred] = useState(false); // Aggiungi stato per errore
  const [isDisablePrinter, setIsDisablePrinter] = useState(false); 
  const reconnectAttemptsRef = useRef(0); // Mantenere il valore aggiornato
  const maxReconnectAttempts = 10;
  const wsRef = useRef(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user?.ownerIp) {
      setErrorOccurred(true);
      return;
    }

    let reconnectTimeout = null; // Per gestire il timeout della riconnessione

    const connectWebSocket = () => {
      
      if (reconnectAttemptsRef.current >= maxReconnectAttempts) {
        console.error('WebSocket: troppi tentativi di riconnessione, interrotto.');
        setErrorOccurred(true); // Segnala l'errore se si raggiungono i tentativi massimi
        return;
      }

      console.log(`Tentativo di connessione WebSocket (${reconnectAttemptsRef.current + 1}/${maxReconnectAttempts})...`);

      try {
        wsRef.current = new WebSocket(`wss://${user.ownerIp}:3001`);

        wsRef.current.onopen = () => {
          console.log('Connesso al server WebSocket per la stampa!');
          setConnectionStatus('Connected');
          reconnectAttemptsRef.current = 0; // Reset tentativi
          setErrorOccurred(false); // Resetta errore
        };

        wsRef.current.onerror = (error) => {
          console.error('Errore nella connessione WebSocket:', error);
          setConnectionStatus('Error');
          setErrorOccurred(true); // Segnala errore se c'è un problema
        };

        wsRef.current.onclose = () => {
          console.log('Connessione WebSocket chiusa');
          setConnectionStatus('Disconnected');

          reconnectAttemptsRef.current += 1;

          if (reconnectAttemptsRef.current < maxReconnectAttempts) {
            reconnectTimeout = setTimeout(() => {
              console.log('Tentativo di riconnessione...');
              connectWebSocket();
            }, 5000);
          } else {
            setErrorOccurred(true); // Segnala errore dopo i tentativi falliti
          }
        };

        wsRef.current.onmessage = (event) => {
          console.log('Messaggio ricevuto:', event.data);
          setIsDisablePrinter(false);
        };
      } catch (error) {
        console.error('Errore imprevisto durante la connessione WebSocket:', error);
        setErrorOccurred(true); // Segnala errore se c'è un problema con la creazione del WebSocket
      }
    };

    connectWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.onclose = null;
        wsRef.current.onerror = null;
        wsRef.current.close();
      }
      if (reconnectTimeout) clearTimeout(reconnectTimeout);
    };
  }, [user?.ownerIp]);

  const sendOrderToPrinter = async (endpoint, payload, sconto, messageType) => {
    try {
      setIsDisablePrinter(true);
      const res = await axios.post(`/api/printer/${endpoint}`, payload);
      if (res.data.success) {
        console.log('Dati ottenuti dal server:', res.data);

        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          const message = {
            type: messageType,
            data: res.data.data,
            sconto: sconto
          };
          wsRef.current.send(JSON.stringify(message));
          console.log('Ordine inviato alla stampante:', message);

        } else {
          console.error('WebSocket non connesso');
        }
      } else {
        console.error('Errore nel recupero dei dati:', res.data.error);
      }
    } catch (error) {
      console.error(
        'Errore nella richiesta al server:',
        error.response ? error.response.data : error.message
      );
    }
  };

  const sendOrderToPrinter_table = (tableId, sconto) => {
    sendOrderToPrinter('orderPrinter_table', { tableId }, sconto, 'PRINT_ORDER_TABLE');
  };

  const sendOrderToPrinter_R_C = (deliveryId, sconto) => {
    sendOrderToPrinter('orderPrinter_R_C', { deliveryId }, sconto, 'PRINT_ORDER_R_C');
  };

  return (
    <PrinterContext.Provider
      value={{
        connectionStatus,
        sendOrderToPrinter_table,
        sendOrderToPrinter_R_C,
        errorOccurred,  // Fornisci l'errore ai componenti figli
        isDisablePrinter // Fornisci lo stato di disabilitazione alla stampante ai componenti figli
      }}
    >
      {children}
    </PrinterContext.Provider>
  );
};
