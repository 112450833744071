import axios from 'axios';

//prende la versione dell'app 
export const getDataUser = async (ownerId) => {
    const data = await axios.post('/api/user/dataUser/get', {ownerId});
    return data.data.version;
}

//vede se il tavolo esiste 
export const getIsValidTable = async (ownerId, tableId) => {
    const data = await axios.post('/api/user/isValidTable/get', {ownerId, tableId});
    return data.data;
}
