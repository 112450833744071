import { CssBaseline, responsiveFontSizes } from "@mui/material";
import { green, red } from '@mui/material/colors';
import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { makeStyles } from "@mui/styles";
import React, { lazy } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import OwnerRoom from "./components/owner/room/owner.room";
import { ChatProvider } from "./contexts/chat.context";
import { DashboardProvider } from "./contexts/dashboard.context";
import { PrinterProvider } from "./contexts/printer.context.jsx";
import { ReservationProvider } from "./contexts/reservation.context";
import { UserProvider } from "./contexts/user.context";
import { OrderProvider } from "./contexts/order.context.jsx";

const CookieConsent = lazy(() =>
  import("./components/customComponents/cookie.consent")
);


const FullScreenError = lazy(() =>
  import("./components/customComponents/fullscreen.error")
);
const PrivateRoute = lazy(() =>
  import("./components/customComponents/private.route")
);
const Route = lazy(() => import("./components/customComponents/route.title"));
const AppBar = lazy(() => import("./components/owner/appBar/owner.app.bar"));
const ConfirmMail = lazy(() =>
  import("./components/owner/confirmMail/owner.confirm.mail")
);
const OwnerDashboard = lazy(() =>
  import("./components/owner/dashboard/owner.dashboard")
);

const OwnerHome = lazy(() =>
  import("./components/owner/home/owner.home.jsx")
);

const OwnerReport = lazy(() =>
  import("./components/owner/report/owner.report")
);

const OwnerMenu = lazy(() => import("./components/owner/menu/owner.menu"));
const OwnerProfile = lazy(() =>
  import("./components/owner/profile/owner.profile")
);
const OwnerSignup = lazy(() =>
  import("./components/owner/signup/owner.signup")
);
const OwnerTables = lazy(() =>
  import("./components/owner/tables/owner.tables")
);
const OwnerDelivery = lazy(() =>
  import("./components/owner/delivery/delivery.jsx")
);
const OwnerChat = lazy(() =>
  import("./components/owner/chat/chat.jsx")
);
const ResetPassword = lazy(() =>
  import("./components/restorePassword/restore.password")
);
const Landing = lazy(() => import("./landing/landing.jsx"));
const Terms = lazy(() => import("./landing/terms"));
const Privacy = lazy(() => import("./landing/privacy"));
const UserSignup = lazy(() => import("./components/user/signup/user.signup"));
const UserBottomNavigation = lazy(() =>
  import("./components/user/bottomNavigation/user.bottom.navigation")
);
const UserBottomNavigationDelivery = lazy(() =>
  import("./components/user/bottomNavigation/user.bottom.navigationDelivery")
);
const SignIn = lazy(() => import("./components/signin"));
const RestorePassword = lazy(() =>
  import("./components/restorePassword/restore.password.request")
);
const OwnerReservation = lazy(() =>
  import("./components/owner/reservation/reservation")
);


const SuccessPaymentDelivery = lazy(() =>
  import("./components/customComponents/success.payment.jsx")
);

//place here global styles to apply in every component
const globalStyles = {
  "@global": {
    //hide default show password button for ms-edge
    "input::-ms-reveal": {
      display: "none",
    },
    "input::-ms-clear": {
      display: "none",
    },
    //custom scrollbar
    "body::-webkit-scrollbar": {
      width: "10px",
      height: 8,
    },
    "body::-webkit-scrollbar-track": {
      boxAhadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    },
    "body::-webkit-scrollbar-thumb": {
      backgroundColor: "#43a047",
      outline: "1px solid slategrey",
    },
    body: {
      //firefox
      "scrollbar-width": "thin",
      "scrollbar-color": "#43a047",
    },
  },
};


const App = () => {
  //const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme =
    responsiveFontSizes(
      //manages responsive font size automatically
      createTheme({
        overrides: {
          MuiCircularProgress: {
            colorPrimary: {
              color: "red",
            },
          },
        },

        palette: {
          mode: "dark",
          primary: {
            main: green[600],
            contrastText: "#fff",
          },
          secondary: {
            main: red[600],
            contrastText: "#fff",
          },
          background: {
            //paper: "#424242" : "#e8e8e8",
            default: '#303030',
            // paper: "#303030"
          },
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: "green",
              },
            }
          },
          MuiDialogContent: {
            styleOverrides: {
              root: {
                backgroundColor: "#303030",
              }
            }
          },
          MuiCircularProgress: {
            styleOverrides: {
              root: {
                color: '#fff'
              }
            }
          }
        },
      })
    );

  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <CookieConsent
          cookieName="cookieConsent"
          message="Questo sito utilizza cookie tecnici e di terze parti. Proseguendo nella navigazione accetti l’utilizzo dei cookie."
          acceptButtonLabel="Chiudi"
        />
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/privacy" title="Informativa privacy">
              <Privacy />
            </Route>
            <Route exact path="/terms" title="Termini e condizioni">
              <Terms />
            </Route>
            <Route exact path="/signin" title="Accedi">
              <SignIn />
            </Route>

            {/* ********************* USER *****************/}
            <Route exact path="/user/signup" title="Registrati">
              <UserSignup />
            </Route>
            <Route
              exact
              path="/user/restorePassword/:requestId/:userId"
              title="Recupero password"
            >
              <ResetPassword />
            </Route>
            <Route exact path="/user/restorePassword" title="Recupero password">
              <RestorePassword />
            </Route>
            <PrivateRoute exact path="/user/home">
              <Landing logged={true} />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/user/reservation/:ownerId/:tableId"
              title="Ordina"
            >
              <PrinterProvider>
                <ReservationProvider>
                  <ChatProvider>
                    <OrderProvider>
                      <UserBottomNavigation section={0} />
                    </OrderProvider>
                  </ChatProvider>
                </ReservationProvider>
              </PrinterProvider>
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/user/history/:ownerId/:tableId"
              title="Ordina"
            >
              <PrinterProvider>
                <ReservationProvider>
                  <ChatProvider>
                    <OrderProvider>
                      <UserBottomNavigation section={1} />
                    </OrderProvider>
                  </ChatProvider>
                </ReservationProvider>
              </PrinterProvider>
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/user/chat/:ownerId/:tableId"
              title="Chat"
            >
              <PrinterProvider>
                <ReservationProvider>
                  <ChatProvider>
                    <OrderProvider>
                      <UserBottomNavigation section={2} />
                    </OrderProvider>
                  </ChatProvider>
                </ReservationProvider>
              </PrinterProvider>
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/user/cart/:ownerId/:tableId"
              title="Ordina"
            >
              <PrinterProvider>
                <ReservationProvider>
                  <ChatProvider>
                    <OrderProvider>
                      <UserBottomNavigation section={3} />
                    </OrderProvider>
                  </ChatProvider>
                </ReservationProvider>
              </PrinterProvider>
            </PrivateRoute>


            <PrivateRoute
              exact
              path="/user/delivery/:ownerId"
              title="Ordina"
            >
              <PrinterProvider>
                <ReservationProvider>
                  <UserBottomNavigationDelivery section={0} />
                </ReservationProvider>
              </PrinterProvider>
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/user/deliverySuccessPayment/:ownerId"
              title="Ordina"
            >
              <PrinterProvider>
                <ReservationProvider>
                  <SuccessPaymentDelivery />
                </ReservationProvider>
              </PrinterProvider>
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/user/deliverycart/:ownerId"
              title="Ordina"
            >
              <PrinterProvider>
                <ReservationProvider>
                  <UserBottomNavigationDelivery section={1} />
                </ReservationProvider>
              </PrinterProvider>
            </PrivateRoute>

            {/* ********************* OWNER *****************/}
            <Route exact path="/owner/signup" title="Registrati">
              <OwnerSignup />
            </Route>
            <Route
              exact
              path="/owner/restorePassword/:requestId/:userId"
              title="Recupero password"
            >
              <ResetPassword />
            </Route>
            <Route
              exact
              path="/owner/restorePassword"
              title="Recupero password"
            >
              <RestorePassword />
            </Route>
            <Route
              exact
              path="/owner/confirmEmail/:token"
              title="Conferma e-mail"
            >
              <ConfirmMail />
            </Route>
            <PrivateRoute exact path="/owner/profile" title="Profilo">
              <AppBar>
                <OwnerProfile />
              </AppBar>
            </PrivateRoute>
            <PrivateRoute exact path="/owner/menu" title="Menu">
              <AppBar>
                <OwnerMenu />
              </AppBar>
            </PrivateRoute>
            <PrivateRoute exact path="/owner/tables" title="Tavoli">
              <AppBar>
                <OwnerTables />
              </AppBar>
            </PrivateRoute>
            <PrivateRoute exact path="/owner/room" title="Sala">
              <PrinterProvider>
                <DashboardProvider>
                  <ReservationProvider>
                    <AppBar>
                      <OwnerRoom />
                    </AppBar>
                  </ReservationProvider>
                </DashboardProvider>
              </PrinterProvider>
            </PrivateRoute>
            <PrivateRoute exact path="/owner/delivery" title="Delivery">
              <PrinterProvider>
                <DashboardProvider>
                  <ReservationProvider>
                    <AppBar>
                      <OwnerDelivery />
                    </AppBar>
                  </ReservationProvider>
                </DashboardProvider>
              </PrinterProvider>
            </PrivateRoute>

            <PrivateRoute exact path="/owner/chat" title="Chat">
              <PrinterProvider>
                <ChatProvider>
                  <DashboardProvider>
                    <ReservationProvider>
                      <AppBar>
                        <OwnerChat />
                      </AppBar>
                    </ReservationProvider>
                  </DashboardProvider>
                </ChatProvider>
              </PrinterProvider>
            </PrivateRoute>

            <PrivateRoute exact path="/owner/dashboard" title="Dashboard">
              <PrinterProvider>
                <DashboardProvider >
                  <AppBar>
                    <OwnerDashboard />
                  </AppBar>
                </DashboardProvider>
              </PrinterProvider>
            </PrivateRoute>

            <PrivateRoute exact path="/owner/home" title="Home">
              <AppBar>
                <OwnerHome />
              </AppBar>
            </PrivateRoute>

            <PrivateRoute exact path="/owner/report" title="Report">
              <PrinterProvider>
                <DashboardProvider>
                  <AppBar>
                    <OwnerReport />
                  </AppBar>
                </DashboardProvider>
              </PrinterProvider>
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/owner/reservation/:ownerId"
              title="Ordina"
            >
              <ReservationProvider>
                <AppBar>
                  <OwnerReservation />
                </AppBar>
              </ReservationProvider>
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/owner/reservation/:ownerId/:tableId"
              title="Ordina"
            >
              <ReservationProvider>
                <AppBar>
                  <OwnerReservation />
                </AppBar>
              </ReservationProvider>
            </PrivateRoute>

            {/* ********************* ERROR 404 *****************/}
            <Route path="*">
              <FullScreenError name="404" text="Pagina non trovata" />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
};

export default withStyles(globalStyles)(App);
