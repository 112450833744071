import { styleVariants, styleOptions } from "./style";

export const getMaxDateOfArray = (dateList) =>
  new Date(Math.max.apply(null, dateList));
export const getMinDateOfArray = (dateList) =>
  new Date(Math.min.apply(null, dateList));
export const addDays = function (date, days) {
  date = new Date(date);
  date.setDate(date.getDate() + days);
  return date;
};
export const cloneState = (data) => JSON.parse(JSON.stringify(data));
export const twoDigits = (num) => (("" + num).length < 2 ? "0" + num : num);
export const toDate = (val) => new Date(val);
export const getItalianDate = (date) =>
  `${date.getDate()}/${twoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
export const getDisplayDate = (originalDate, withoutHour) => {
  if (!originalDate) {
    return "";
  }
  originalDate = new Date(originalDate);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const compDate = new Date(originalDate);
  compDate.setHours(0, 0, 0, 0);
  return `${compDate.getTime() === today.getTime()
    ? "oggi"
    : compDate.getTime() === addDays(today, -1).getTime()
      ? "ieri"
      : getItalianDate(originalDate)
    }${withoutHour
      ? ""
      : ` alle ${twoDigits(originalDate.getHours())}:${twoDigits(
        originalDate.getMinutes()
      )}`
    }`;
};
export const zeroHours = (date) => {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDisplayHour = (originalDate) => {
  if (!originalDate) {
    return "";
  }
  originalDate = new Date(originalDate);
  return `${twoDigits(originalDate.getHours())}:${twoDigits(
    originalDate.getMinutes()
  )}`;
};
export const redirectAfterUserLogin = (history, user) => {
  const precUrl = sessionStorage.getItem("precUrl");
  if (precUrl) {
    history.push(precUrl);
    sessionStorage.removeItem("precUrl");
  } else {
    history.push(user.type === "ownerReport" ? "/owner/report" : "/owner/home");
  }
};
export const getScrollY = () =>
  window.pageYOffset || document.documentElement.scrollTop;

// export const numberToRGB = (colorNum, colors) => {
//   if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
//   return "hsl(" + ((colorNum * (360 / colors)) % 360) + ",100%,50%)";
// };

export const numberToRGB = (colorNum, colors) => {
  if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
  const angleMultiplier = 3; // Increase the separation angle
  return "hsl(" + ((colorNum * (360 / colors) * angleMultiplier) % 360) + ",100%,50%)";
};


export const toDateTime = (date) =>
  new Date(date).toISOString().slice(0, 19).replace("T", " ");


export const ottieniGiornoMeseAnno = (dataString) => {
  const data = new Date(dataString);
  // Ottieni giorno, mese e anno
  const giorno = data.getDate();
  const mese = String(data.getMonth() + 1).padStart(2, '0'); // Aggiunge uno zero iniziale se necessario
  const anno = data.getFullYear();
  return (giorno + '/' + mese + '/' + anno).toString();
}

// Logica di filtraggio
export const filterForPrintedAndSelectedOption = (data) => {
  const filter = localStorage.getItem('filterRes') || 'tutto';
  return data.map(data => {
    // Filtra le reservations in base al filtro
    const filteredReservations = data.reservations.filter(reservation => {
      if (reservation.printed !== 0) return false; // esclude le prenotazioni già stampate
      if (reservation.accettato === 0) return false; // esclude consegne e ritiro non ancora accettate
      if (filter === 'tutto') return reservation.selected_option === 'cucina' || reservation.selected_option === 'bar' || reservation.selected_option === 'fritti';
      return reservation.selected_option === filter;
    });

    // Se ci sono reservations filtrate, restituisci il tavolo aggiornato, altrimenti null
    return filteredReservations.length > 0 ? { ...data, reservations: filteredReservations } : null;
  }).filter(data => data !== null); // Filtra fuori i tavoli senza reservations

}


export const calcolaOrariDisponibili = (futureTimes, openingHour, openingMinutes, closingHour, closingMinutes, currentHour, currentMinutes) => {
  if (closingHour >= openingHour) {
    for (let hour = openingHour; hour <= closingHour; hour++) {
      const startMinutes = hour === openingHour ? openingMinutes : 0;
      for (let minutes = startMinutes; minutes < 60; minutes += 15) {
        if (hour === closingHour && minutes > closingMinutes) {
          break;
        }
        if (hour > currentHour || (hour === currentHour && minutes >= currentMinutes)) {
          const formattedHour = (hour % 24).toString().padStart(2, '0');
          const formattedMinutes = minutes.toString().padStart(2, '0');
          futureTimes.push(`${formattedHour}:${formattedMinutes}`);
        }
      }
    }
  } else {
    for (let hour = openingHour; hour < 24; hour++) {
      const startMinutes = hour === openingHour ? openingMinutes : 0;
      for (let minutes = startMinutes; minutes < 60; minutes += 15) {
        if (hour === closingHour && minutes > closingMinutes) {
          break;
        } 
        if (hour > currentHour || (hour === currentHour && minutes >= currentMinutes)) {
          const formattedHour = (hour % 24).toString().padStart(2, '0');
          const formattedMinutes = minutes.toString().padStart(2, '0');
          futureTimes.push(`${formattedHour}:${formattedMinutes}`);
        }
      }
    }
    for (let hour = 0; hour <= closingHour; hour++) {
      const startMinutes = hour === openingHour ? openingMinutes : 0;
      for (let minutes = startMinutes; minutes < 60; minutes += 15) {
        if (hour === closingHour && minutes > closingMinutes) {
          break;
        }
        const formattedHour = (hour % 24).toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        futureTimes.push(`${formattedHour}:${formattedMinutes}`);
      }
    }
  }
}

export function rimuoviOrariOccupati(orariDisponibili, capacitaProduzione, indiceProduzione) {
  let orariRimasti = [...orariDisponibili]; // Copia immutabile

  // Gestione del caso orario: null
  if (capacitaProduzione.length !== 0 && capacitaProduzione.some(el => el.orario === null)) {
    const itemInCucina = capacitaProduzione.filter(el => el.orario === null);
    const intervalliNecessari = Math.floor(itemInCucina[0].amount / indiceProduzione);
    orariRimasti = orariDisponibili.slice(intervalliNecessari);
  }

  // Gestione di orari precisi
  capacitaProduzione.forEach(item => {
    if (item.orario?.includes("-")) {
      // Caso di intervallo di tempo (es. "13:00 - 13:15")
      let [oraInizio] = item.orario.split(" - ");
      let indexInizio = orariRimasti.indexOf(oraInizio);

      if (indexInizio !== -1) {
        // Calcolare quanti intervalli rimuovere
        const intervalliNecessari = Math.floor(item.amount / indiceProduzione);
        orariRimasti.splice(indexInizio, intervalliNecessari);
      }
    } else {
      // Caso di orario singolo
      let index = orariRimasti.indexOf(item.orario);
      if (index !== -1 && item.amount >= indiceProduzione) {
        // Rimuovi l'orario e i successivi in base alla quantità
        const intervalliNecessari = Math.floor(item.amount / indiceProduzione);
        orariRimasti.splice(index, intervalliNecessari);
      }
    }
  });

  // Verifica la distanza tra i primi due intervalli
  const intervalloMaxDiff = 15; // Max distanza tra intervalli in minuti
  let diff = (new Date(`1970-01-01T${orariRimasti[1]}:00`).getTime() - new Date(`1970-01-01T${orariRimasti[0]}:00`).getTime()) / 60000;

  // Se la differenza tra i primi due intervalli è maggiore di 15 minuti, nascondi l'opzione "prima possibile"
  let mostraPrimaPossibile = true; // Dichiaro la variabile mostraPrimaPossibile

  if (diff > intervalloMaxDiff) {
    mostraPrimaPossibile = false; // Cambia lo stato se l'intervallo è troppo grande
  }

  return {
    mostraPrimaPossibile: mostraPrimaPossibile, // Restituisci lo stato dell'opzione
    //orariRimasti: orariRimasti.slice(1) // Mostra solo gli orari successivi a quelli selezionati
    orariRimasti: orariRimasti
  };
}



export function filtraOrariConsegne(orariDisponibili, capacitaProduzione) {
  // Estrai gli orari occupati da consegne
  const orariOccupati = new Set(
    capacitaProduzione.map(consegna => consegna.orario)
  );

  // Filtra gli orari disponibili
  const orariRimasti = orariDisponibili.filter(orario => {
    // Caso intervallo (es. "16:30 - 16:45")
    if (Array.from(orariOccupati).some(occupato => occupato.includes("-"))) {
      const occupatiIntervallo = Array.from(orariOccupati).map(occupato => occupato.split(" - ")[0]);
      return !occupatiIntervallo.includes(orario); // Rimuovi solo il primo slot dell'intervallo
    }

    // Caso orario singolo (es. "18:00")
    return !orariOccupati.has(orario);
  });

  // Verifica la distanza tra i primi due intervalli
  const intervalloMaxDiff = 15; // Max distanza tra intervalli in minuti
  let diff = (new Date(`1970-01-01T${orariRimasti[1]}:00`).getTime() - new Date(`1970-01-01T${orariRimasti[0]}:00`).getTime()) / 60000;

  // Se la differenza tra i primi due intervalli è maggiore di 15 minuti, nascondi l'opzione "prima possibile"
  let mostraPrimaPossibile = true; // Dichiaro la variabile mostraPrimaPossibile

  if ((diff > intervalloMaxDiff) || (diff < 0)) {
    mostraPrimaPossibile = false; // Cambia lo stato se l'intervallo è troppo grande
  }

  return {
    mostraPrimaPossibile: mostraPrimaPossibile, // Restituisci lo stato dell'opzione
    orariRimasti: orariRimasti
  };

}





export const isOpen = (currentTimeDB, openingTime, closingTime) => {
  const [openHours, openMinutes, openSeconds] = openingTime.split(':').map(Number);
  const [closeHours, closeMinutes, closeSeconds] = closingTime.split(':').map(Number);

  const now = currentTimeDB;
  const splittedTime = now.split(':')
  const currentHours = Number(splittedTime[0]);
  const currentMinutes = Number(splittedTime[1]);
  // const currentSeconds = Number(splittedTime[2].substring(0, 3).trim());

  const openTime = new Date();
  openTime.setHours(openHours, openMinutes, openSeconds, 0);

  const closeTime = new Date();
  closeTime.setHours(closeHours, closeMinutes, closeSeconds, 0);

  const currentTime = new Date();
  currentTime.setHours(currentHours, currentMinutes, 0, 0);

  if (closeTime < openTime) {
    // Locale aperto a cavallo della mezzanotte
    return currentTime >= openTime || currentTime < closeTime;
  } else {
    // Locale aperto nello stesso giorno
    return currentTime >= openTime && currentTime < closeTime;
  }
}

export const getFees = (amount) => {
  const stripeFees = (amount * 0.0325) + 0.25;
  const stripeIva = stripeFees * 0.22;
  const myOrdersFees = (amount * 0.005) + 0.10;
  return (stripeFees + stripeIva + myOrdersFees);


}

export const calculateTotalWithFees = (netAmount, isReturnFees, paymentOption) => {
  const stripePercentage = 0.0325; // 3.25%
  const stripeFixed = 0.25; // €0.25 fixed fee
  const stripeIva = 0.22; // 22% IVA

  const myPercentage = 0.005; // 0.5%
  const myFixed = 0.10; // €0.10 fixed fee

  if (paymentOption?.toLowerCase() === 'carta') {
    if (isReturnFees) {
      return (((netAmount + stripeFixed * (1 + stripeIva) + myFixed) /
        (1 - (stripePercentage * (1 + stripeIva) + myPercentage))) - netAmount).toFixed(2);
    } else {
      return ((netAmount + stripeFixed * (1 + stripeIva) + myFixed) /
        (1 - (stripePercentage * (1 + stripeIva) + myPercentage))).toFixed(2);
    }
  } else {
    return netAmount.toFixed(2)
  }
};




export const formatVariants = (variantString, refStyle) => {
  // Split by line breaks to get each category with its options
  if (variantString) {
    const categoryOptionsArray = variantString.split('\n\n').filter(Boolean);
    // Create a formatted list where categories are bolded and options are regular
    return categoryOptionsArray.map((categoryOptions, index) => {
      const [category, options] = categoryOptions.split(': ');
      if (refStyle === undefined) {
        return (
          <div key={index} style={{ marginBottom: '8px' }}>
            <strong style={{ ...styleVariants, fontSize: '16px' }}>{category}:</strong>
            <span style={{ ...styleOptions, fontSize: '14px' }}> {options}</span>
          </div>
        );
      } else {
        return (
          <div key={index} style={{ marginBottom: '8px' }}>
            <span>{category}:</span>
            <strong> {options}</strong>
          </div>
        );
      }

    });
  }
}



export const getSelectedVariantsForPrintView = (variants) => {
  // Creiamo un oggetto per raggruppare le opzioni selezionate
  if (variants === undefined) return null;
  const groupedVariants = {};

  variants.forEach(variant => {
    const { name, position, options, selected } = variant;

    // Inizializziamo l'array per il nome della variante se non esiste già
    if (!groupedVariants[name]) {
      groupedVariants[name] = {
        options: [],
        position: position // Memorizziamo la posizione
      };
    }

    const selectedOptions = options.filter(option => selected.some(sel => sel.id === option.id));
    const selectedOptionNames = selectedOptions.map(option => option.label);
    groupedVariants[name].options.push(...selectedOptionNames); // Aggiungiamo le opzioni selezionate all'array corrispondente

  });

  // Ordiniamo le varianti in base alla posizione
  const sortedVariants = Object.entries(groupedVariants).sort((a, b) => {
    return a[1].position - b[1].position; // Ordina per posizione
  })

  // Ora convertiamo l'oggetto in una stringa formattata
  return sortedVariants.map(([variantName, { options }]) => {
    return `${variantName}: ${options.join(', ')}`; // Uniamo le opzioni selezionate con una virgola
  }).join('\n\n');


};



export const orderByFirstUpdate = (tables) => tables.sort((table1, table2) => {
  const lastUpdate1 = getMinDateOfArray(
      table1.reservations.map(({ lastUpdate }) => toDate(lastUpdate))
  );
  const lastUpdate2 = getMinDateOfArray(
      table2.reservations.map(({ lastUpdate }) => toDate(lastUpdate))
  );

  return lastUpdate1.getTime() - lastUpdate2.getTime();
});


export const orderByTime = (tables) => tables.sort((table1, table2) => {
  const time1 = toTime(table1.orario);
  const time2 = toTime(table2.orario);

  return time1 - time2;
});

// Funzione per convertire l'orario in minuti (per confronto più facile)
export const toTime = (timeStr) => {
  if (!timeStr || typeof timeStr !== 'string') return 0; // Se manca, lo spostiamo in fondo

  const cleanTime = timeStr.split(' - ')[0]; // Prende solo l'orario iniziale in caso di intervallo
  const [hours, minutes] = cleanTime.split(':').map(Number);

  let totalMinutes = (hours || 0) * 60 + (minutes || 0); 

  // Se l'orario è tra mezzanotte e le 05:00, lo consideriamo "del giorno successivo"
  if (hours >= 0 && hours < 4) {
      totalMinutes += 1440; // Aggiungiamo 24 ore in minuti
  }

  return totalMinutes;
};