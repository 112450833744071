import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import { UserContext } from './user.context';
import { getIsValidTable } from "../components/user/getDataUser";

const useChat = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useContext(UserContext);
	const [socket, setSocket] = useState(null);
	const [connected, setConnected] = useState(false);
	let { ownerId, tableId } = useParams();
	const [enabled, setEnabled] = useState(null);
	const [scrollChat, setScrollChat] = useState(true);
	const [newMessage, setNewMessage] = useState(false);
	const [isValidTable, setIsValidTable] = useState(false);

	if (user.type === 'user') {
		ownerId = decodeURIComponent(ownerId);
		tableId = decodeURIComponent(tableId);
	} else {
		ownerId = user.id;
		tableId = user.name
	}

	const [chat, setChat] = useState([]);

	//connect to socket
	useEffect(() => {
		if (user && user.id) {

			if (user.type === 'user') {
				const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
					secure: true,
					transports: ['websocket'],
					query: { ownerId, type: 'user' },
				});
				setSocket(s);
			}
			if (user.type === 'owner') {
				const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
					secure: true,
					transports: ["websocket"],
					query: { ownerId: user.id },
				});
				setSocket(s);
			}

		}// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		let isMounted = true; // track if component is mounted

		const fetchChatEnablement = async () => {
			try {
				setIsLoading(true); // Set loading state before making the request

				let data;
				if (user.type === 'user') {
					({ data } = await axios.post('/api/user/chat/get', { ownerId }));
					const validTable = await getIsValidTable(ownerId, tableId);
					setIsValidTable(validTable.success)
				} else if (user.type === 'owner') {
					({ data } = await axios.post('/api/owner/chat/get', { ownerId }));
					setIsValidTable(true)
				}

				if (isMounted) { // update state only if component is still mounted
					setEnabled(Boolean(data.chatEnabled));
					setIsLoading(false); // Set loading state after the request completes
				}
			} catch (error) {
				console.error('Error fetching chat enablement:', error);
				if (isMounted) { // ensure loading state is reset in case of error
					setIsLoading(false);
				}
			}
		};

		fetchChatEnablement();

		return () => {
			isMounted = false; // cleanup on unmount
		};
	}, [user.type, ownerId]);

	//manage real time events
	useEffect(() => {
		if (socket) {
			socket.on('connect', () => {
				setConnected(true);
			});
			socket.on('disconnect', () => {
				setConnected(false);
			});
			socket.on('recieveMessage', recieveMessage);
			socket.on('syncMessages', syncMessages);
			socket.on('disableChat', disableChat);
			socket.on('enableChat', enableChat);

			return () => {
				socket.off('disableChat');
				socket.off('connect');
				socket.off('disconnect');
				socket.off('recieveMessage');
				socket.off('syncMessages');
				socket.off('enableChat');
			};
		}
	}, [socket, isLoading]);

	const enableChat = () => setEnabled(true);

	const disableChat = () => {
		setChat([]);
		setEnabled(false);
	};

	const syncMessages = (msgs) => {
		setIsLoading(false);
		if (msgs) {
			setChat(msgs);
		}
	};

	const recieveMessage = (msg) => {
		setChat((c) => [...c, msg]);
	};

	const sendMessage = (text) => {
		socket.emit('sendMessage', { ownerId, tableId, text });
		setChat((c) => [...c, { tableId: null, text, time: Date.now() }]);
		setScrollChat(true);
		setNewMessage(false);
	};

	return {
		isLoading,
		sendMessage,
		enabled,
		chat,
		connected,
		scrollChat,
		setScrollChat,
		setNewMessage,
		newMessage,
		isValidTable
	};
};

export const ChatContext = createContext();

export const ChatProvider = (props) => {
	const dashboard = useChat();

	return (
		<>
			<ChatContext.Provider value={dashboard}>{props.children}</ChatContext.Provider>
		</>
	);
};
