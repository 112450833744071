import {
  AppBar,
  Button,
  Grid,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  TextField,
  IconButton,
  CircularProgress
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useState } from "react";
import axios from 'axios';
import { DashboardContext } from "../../../contexts/dashboard.context";
import { ReservationContext } from "../../../contexts/reservation.context";
import { PrinterContext } from "../../../contexts/printer.context";
import { getDisplayDate, getMaxDateOfArray, toDate, calculateTotalWithFees } from "../../../functions";
import OwnerRoomDishRow from "./owner.room.dish.row";
import RoomViewMobile from "./owner.room.dialog.view.mobile";
import useMediaQuery from '@mui/material/useMediaQuery';
import RoomViewPrint from './owner.room.dialog.view.print';
import { NumberFormatFloat } from "../../customComponents/number.format.input";
import DialogMenu from './owner.room.dialog.menu'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from "@mui/icons-material/Remove";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ConfirmDialog from "../../customComponents/confirm.dialog";
import PrintIcon from '@mui/icons-material/Print';
//import "./printBill.css";



const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    minWidth: 300,
  },
  dialogContent: { marginTop: theme.spacing(3) },
  deletedDish: {
    textDecoration: "line-through",
  },
  spacingLeft: {
    marginLeft: theme.spacing(2),
  },
  centeredIcon: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '200px',
    gap: '40px'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const _NumberFormatFloat = React.forwardRef(function _NumberFormatFloat(props, ref) {
  return <NumberFormatFloat {...props} getInputRef={ref} />;
});

export default function RoomDialogView({
  openDialogView: open,
  setOpenDialogView: setOpen,
  tableId,
  businessId,
  settingsDataDelivery,
  deliveryId,
  paymentIntentId,
  accountIdPayment
}) {
  const { data, modifyTable, removeTable, handleAcceptOrder, handleRejectOrder, removeDelivery } = useContext(DashboardContext);
  const { sendOrderToPrinter_table, sendOrderToPrinter_R_C, errorOccurred, isDisablePrinter } = useContext(PrinterContext);
  const { eraseOrder } = useContext(ReservationContext);

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogPrintMobile, setOpenDialogPrintMobile] = useState(false);
  const [scontoTotalPrice, setScontoTotalPrice] = useState(0);
  const [nuovoTotalPrice, setNuovoTotalPrice] = useState(0);
  const [openDialogMenu, setOpenDialogMenu] = useState(false);
  const [dataMenuObj, setDataMenuObj] = useState({ dataMenu: [], categories: [] })
  const [openDialogComplete, setOpenDialogComplete] = useState(false);
  const i = data.findIndex((res) => (res.tableId || res.deliveryId) === tableId);
  const table = i === -1 ? null : data[i];
  const isMobile = useMediaQuery("(max-width:576px)");
  const classes = useStyles();


  const totalPrice =
    i === -1
      ? null
      : table.reservations.reduce(
        (acc, { price, amount, status }) => {
          if (status !== "owner_deleted") {
            return acc + price * amount;
          } else {
            return acc;
          }
        },
        0
      );


  const lastUpdate =
    i === -1
      ? null
      : getMaxDateOfArray(
        table.reservations.map(({ lastUpdate }) => toDate(lastUpdate))
      );

  const handleClose = () => {
    setOpen(false);
    setScontoTotalPrice(0);
    setOpenDialogPrintMobile(false);
  };

  const handleClickEdit = () => {
    modifyTable(table.id, table);
    setIsEditing((s) => !s);
  };

  const handleClickPrintRemove = () => {
    //avvia stampa
    openDialogMobile();
  }

  const handleClickRemove = () => {
    removeTable((table.tableId || table.deliveryId), false, false, false, table.deliveryId ? true : false);
    setOpen(!open);
  }

  const handleChangeTotalPrice = (sconto, refComponent) => {
    if (refComponent === 'dishRow') {
      setScontoTotalPrice(0)
    } else {
      setScontoTotalPrice(Number(sconto));
      const scontoApplicato = getSumTotal() - sconto;
      setNuovoTotalPrice(Number(scontoApplicato));
    }

  }

  const openDialogMobile = () => {
    setOpenDialogPrintMobile(!openDialogPrintMobile)
  }

  const getCategory = (dishes) => {
    const c = [];
    dishes.forEach(({ categoryId, categoryName }) => {
      const catId = c.map(({ id }) => id);
      if (catId.indexOf(categoryId) === -1) {
        c.push({
          id: categoryId,
          name: categoryName,
        });
      }
    });
    return c
  }


  const handleClickOrder = () => {
    // FAI GIRARE (forse)
    if (!openDialogMenu) {
      axios.post('/api/menu/owner/order/get', { businessId }).then(res => {
        let categories = getCategory(res.data.data);
        setDataMenuObj({ dataMenu: res.data.data, categories });

      })
    } else {
      sessionStorage.removeItem('cart');
      eraseOrder();
    }
    setOpenDialogMenu(!openDialogMenu);
  }

  const _handleCloseDialogAccept = (deliveryId) => {
    handleAcceptOrder(deliveryId)
    for (let x of table.reservations) {
      x.accettato = 1;
    }
    table.accettato = table.reservations[0].accettato
    //setOpen(!open);
    //per aprire la pagina bianca del print 
    if (errorOccurred) { handleClickPrintRemove(); }
  };

  const _handleCloseDialogReject = (deliveryId) => {
    setIsLoading(true);
    if (paymentIntentId) {
      axios.post('/api/stripe/refund-stripe', { paymentIntentId, accountIdPayment }).then((() => {
        removeDelivery(deliveryId);
        handleRejectOrder(deliveryId);
      })).finally(() => {
        setOpen(!open)
        setIsLoading(false);
      })
    } else {
      removeDelivery(deliveryId);
      handleRejectOrder(deliveryId);
      setOpen(!open)
      setIsLoading(false);
    }
  };


  const getSumTotal = () => {
    if (table.tipo) {
      let costoConsegna = 0;

      if (table.tipo.toLowerCase() === 'consegna')
        costoConsegna = parseFloat(settingsDataDelivery.costoConsegna);

      return (totalPrice + costoConsegna).toFixed(2);
    }
    return totalPrice.toFixed(2)


  }

  const handleClickComplete = (result) => {
    if (result) {
      let costoConsegna = 0;
      if (table?.tipo?.toLowerCase() === 'consegna') { costoConsegna = Number(settingsDataDelivery?.costoConsegna); }
      const totalePagato = scontoTotalPrice !== 0 ? nuovoTotalPrice : totalPrice + costoConsegna;

      axios.post('/api/dashboard/owner/table/history', { table: table, totaleOrdine: totalPrice, costoConsegna, totalePagato }).then(() => {
        handleClose()
        removeTable((table.tableId || table.deliveryId), false, false, false, table.deliveryId ? true : false);
      })

    }
    setOpenDialogComplete(!openDialogComplete);
  }

  return (
    <div>
      <ConfirmDialog
        text={{
          text: 'Sei sicuro di voler completare l\'ordine?',
          description: `Attenzione: completando l'ordine, questo verrà eliminato definitivamente e non sarà più recuperabile`,
        }}
        handleClose={handleClickComplete}
        open={openDialogComplete}
      />
      <Dialog
        onClose={handleClose}
        open={open}
        fullScreen
        TransitionComponent={Transition}
      >
        {!isMobile ? (
          <MuiDialogContent dividers className={classes.dialogContent}>
            <AppBar>
              <Toolbar >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">
                  {table ? (table?.tableId ? `Tavolo ${table.tableId}` : table?.tipo + ' - ' + table?.nome + ' ' + table?.cognome) : 'Tavolo ' + tableId}
                </Typography>
              </Toolbar>
            </AppBar>

            {/** VERSIONE STAMPABILE*/}
            <Dialog onClose={openDialogMobile} open={openDialogPrintMobile} fullScreen TransitionComponent={Transition}>
              <MuiDialogContent dividers className={classes.dialogContent}>
                <RoomViewPrint paymentIntentId={paymentIntentId} handleClose={handleClose} classes={classes} tableId={tableId} table={table} statesSconti={{ nuovoTotalPrice, scontoTotalPrice, costoConsegna: settingsDataDelivery?.costoConsegna }} />
              </MuiDialogContent>
            </Dialog>
            {/** VERSIONE STAMPABILE*/}

            <DialogMenu tableId={tableId} ownerId={businessId} deliveryId={table?.deliveryId} form={table?.tipo + ' - ' + table?.nome + ' ' + table?.cognome} openDialog={openDialogMenu} setOpenDialog={setOpenDialogMenu} handleClickOrder={handleClickOrder} dataMenuObj={dataMenuObj} />

            {table && table.reservations.length !== 0 ? (
              <>
                <Grid container alignItems="center">
                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px', fontSize: '14px' }}>
                      <CheckCircleIcon color="primary" style={{ fontSize: '14px' }} />
                      <Typography color='textSecondary' variant="body2" component="h2">Completato</Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px', fontSize: '14px' }}>
                      <AccessTimeFilledIcon color="warning" style={{ fontSize: '14px' }} />
                      <Typography color='textSecondary' variant="body2" component="h2">In preparazione</Typography>
                    </div>
                  </Grid>

                  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography gutterBottom variant="body2" component="h2" color="textSecondary">
                      Ultima ordinazione: {getDisplayDate(lastUpdate)}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {totalPrice > 0 && table.accettato !== 0 && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ padding: "4px 8px", fontSize: "12px", minWidth: "auto" }}
                        onClick={() => setOpenDialogComplete(!openDialogComplete)}
                      >
                        Completa ordine
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow >
                        <TableCell style={{ width: '20%' }}>Quantità</TableCell>
                        <TableCell style={{ width: '20%' }}>Nome</TableCell>
                        <TableCell style={{ width: '20%' }} align="center">Variante</TableCell>
                        <TableCell style={{ width: '20%' }} align="center" >Note</TableCell>
                        <TableCell style={{ width: '20%' }} align="center">Prezzo</TableCell>
                        <TableCell style={{ width: '20%' }} align="right">
                          {isEditing && "Azioni"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table.reservations.map((dish, index) => (
                        <OwnerRoomDishRow
                          key={index}
                          dish={dish}
                          classes={classes}
                          tableId={tableId || dish.deliveryId}
                          isEditing={isEditing}
                          handleChangeTotalPrice={handleChangeTotalPrice}
                          setScontoTotalPrice={setScontoTotalPrice}
                          accettato={table.accettato}
                        />
                      ))}

                      {table.deliveryId && table?.tipo.toLowerCase() === 'consegna' && (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={4} align="right">
                            <Typography color="primary">Subtotale</Typography>
                          </TableCell>

                          <TableCell align="center">
                            <Typography color="primary">
                              {totalPrice ? totalPrice.toFixed(2).toString().replace('.', ',') : 0} €
                            </Typography>
                          </TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      )}



                      {table.tipo ? table?.tipo.toLowerCase() === 'consegna' && (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={4} align="right">
                            <Typography color="primary">Costo consegna</Typography>
                          </TableCell>

                          <TableCell align="center">
                            <Typography color="primary">
                              {settingsDataDelivery.costoConsegna ? parseFloat(settingsDataDelivery.costoConsegna).toFixed(2).toString().replace('.', ',') : 0} €
                            </Typography>
                          </TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      ) : <></>}

                      {(paymentIntentId && paymentIntentId.toLowerCase() !== 'pagato') ? (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={4} align="right">
                            <Typography color="primary">Costi di servizio</Typography>
                          </TableCell>

                          <TableCell align="center">
                            <Typography color="primary">
                              {calculateTotalWithFees(Number(getSumTotal()), true, paymentIntentId ? 'carta' : 'cassa').replace('.', ',')} €
                            </Typography>
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      ) : <></>}

                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={4} align="right">
                          <Typography color="primary">TOTALE</Typography>
                        </TableCell>

                        <TableCell align="center">
                          <Typography color="primary">
                            {calculateTotalWithFees(Number(getSumTotal()), false, (paymentIntentId && paymentIntentId.toLowerCase() !== 'pagato') ? 'carta' : 'cassa').replace('.', ',')} €
                          </Typography>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>

                      {(table.accettato === 1 || table.accettato === null) && !paymentIntentId ? (
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            colSpan={3}
                            align="right"
                          >

                          </TableCell>

                          {scontoTotalPrice < 0.5 ? (<TableCell align="right"></TableCell>) : (
                            <TableCell align="right">
                              <Button onClick={() => setScontoTotalPrice(scontoTotalPrice - 0.5)}>
                                <RemoveIcon fontSize='large' color='primary' />
                              </Button>
                            </TableCell>
                          )}


                          <TableCell align="center" >

                            <TextField
                              style={{ width: '75px' }}
                              label="Sconto"
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              value={scontoTotalPrice}
                              className={classes.spacingBottom}
                              InputProps={{
                                inputComponent: _NumberFormatFloat,
                              }}
                              onChange={(e) =>
                                handleChangeTotalPrice(e.target.value)
                              }>

                            </TextField>

                          </TableCell>

                          {scontoTotalPrice >= totalPrice ? (<TableCell align="right"></TableCell>) : (
                            <TableCell align="center">
                              <Button onClick={() => setScontoTotalPrice(scontoTotalPrice + 0.5)}>
                                <AddIcon fontSize='large' color='primary' />
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>) : (<></>)}


                      {scontoTotalPrice !== 0 ? (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              colSpan={4}
                              align="right"
                            >
                              <Typography color="primary">NUOVO TOTALE</Typography>
                            </TableCell>

                            <TableCell align="center">
                              <Typography color="primary">{nuovoTotalPrice.toFixed(2).toString().replace('.', ',') + ' €'}</Typography>
                            </TableCell>
                            <TableCell align="center"></TableCell>
                          </TableRow>


                        </>
                      ) : (<></>)}

                      <TableRow>
                        {!isEditing ? (
                          <>
                            {table.accettato === 0 ? (
                              <>
                                <TableCell component="th" scope="row" colSpan={2} >
                                  <Button
                                    style={{ marginRight: "20px" }}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => _handleCloseDialogReject(table.deliveryId)}
                                    disabled={isLoading}
                                  >
                                    {isLoading ? <CircularProgress size={24} /> : "Rifiuta ordine"}
                                  </Button>
                                </TableCell>

                                <TableCell colSpan={5} align="right">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => _handleCloseDialogAccept(table.deliveryId)}
                                    disabled={isLoading}
                                  >
                                    Accetta ordine
                                  </Button>
                                </TableCell>

                              </>

                            ) : (
                              <>
                                <TableCell component="th" scope="row" colSpan={2}>
                                  <Button
                                    style={{ marginRight: "20px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClickOrder}
                                  >
                                    Ordina
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClickEdit}
                                  >
                                    Modifica
                                  </Button>
                                </TableCell>
                              </>

                            )}


                            {table.accettato !== 0 && (
                              <TableCell
                                component="th"
                                scope="row"
                                colSpan={5}
                                align="right"
                              >
                                {totalPrice > 0 ? (
                                  <>
                                    {!errorOccurred && (
                                      <Button
                                        style={{ marginRight: "50px" }}
                                        variant="outlined"
                                        onClick={() => table.tableId ? sendOrderToPrinter_table(table.tableId, scontoTotalPrice) : sendOrderToPrinter_R_C(table.deliveryId, scontoTotalPrice)}
                                        startIcon={<PrintIcon fontSize="medium" color={isDisablePrinter ? '' : "primary"} />}
                                        disabled={isDisablePrinter}

                                      >
                                        Stampa
                                      </Button>
                                    )}

                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={handleClickPrintRemove}
                                    >
                                      stampa con il browser
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClickRemove}
                                  >
                                    elimina ordini
                                  </Button>
                                )}


                              </TableCell>
                            )}
                          </>
                        ) : (
                          <>
                            <TableCell
                              component="th"
                              scope="row"
                              colSpan={6}
                              align="right"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClickEdit}
                              >
                                Termina
                              </Button>
                            </TableCell>
                          </>
                        )}
                        {/*  <TableCell align="right">
                          <Typography color="primary"></Typography>
                        </TableCell> */}
                      </TableRow>



                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <div className={classes.centeredIcon}>
                <PersonOffIcon color='primary' style={{ fontSize: '120px' }} />
                <Typography
                  variant='subtitle1'
                  color='textSecondary'
                  style={{ textAlign: 'center', letterSpacing: '1px' }}>
                  Non ci sono ordini associati a questo tavolo al momento
                </Typography>
              </div>
            )}
          </MuiDialogContent>
        )
          : <RoomViewMobile businessId={businessId} handleClose={handleClose} classes={classes} tableId={tableId} table={table} handleCloseDialogAccept={_handleCloseDialogAccept} handleCloseDialogReject={_handleCloseDialogReject} /* data={data}  */ />
        }
      </Dialog>
    </div>
  );
}
