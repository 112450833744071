import React, { useState } from 'react';
import { DialogActions, DialogContent, Dialog, AppBar, Toolbar, IconButton, Typography, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import imageCard from '../../images/card-recive-svgrepo-com.svg'

function DialogPaymentStripe({ setIsOpenDialogPayment, isOpenDialogPayment, formData, ownerId, getSocketId, isOwner, paymentIntentId }) {
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();


    const handlePayAndOrder = async () => {
        setLoading(true);


        if (!stripe || !elements) {
            // Stripe.js non è pronto
            setLoading(false);
            return;
        }

        try {
            sessionStorage.setItem("dataClient", JSON.stringify({ formData, ownerId, socketId: getSocketId(), isOwner, paymentIntentId }));

            // Conferma il pagamento con Stripe
            // eslint-disable-next-line
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: window.location.origin + `/user/deliverySuccessPayment/${encodeURIComponent(ownerId)}`, // Cambia con il tuo URL di successo
                    payment_method_data: {
                        billing_details: {
                            address: {
                                country: 'IT', // Cambia con il tuo paese
                            }
                        }
                    }

                }
            });

        } catch (error) {
            console.error("Errore nel processo dell'ordine:", error);
        } finally {
            // Riabilita il pulsante
            setLoading(false);
        }
    };

    return (

        <Dialog open={isOpenDialogPayment} onClose={() => setIsOpenDialogPayment(!isOpenDialogPayment)} fullScreen={true} >

            <AppBar position='static'>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setIsOpenDialogPayment(!isOpenDialogPayment)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">{'Paga e invia ordine'}</Typography>
                </Toolbar>
            </AppBar>

            <DialogContent >
                <div style={loading ? { visibility: 'hidden', height: '0px' } : { visibility: 'visible' }}>
                    <PaymentElement
                        options={{
                            fields: {
                                billingDetails: {
                                    address: {
                                        country: 'never', // Nascondi il campo paese
                                    },
                                },
                            },
                        }}
                    />
                </div>

                {loading && (
                    <div style={{ textAlign: 'center' }}>
                        <img alt='card-payment' src={imageCard} />
                    </div>
                )}


                <Button
                    size='small'
                    variant="contained"
                    color="primary"
                    onClick={handlePayAndOrder}
                    disabled={loading}
                    style={{ width: '100%', marginTop: 30 }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Paga e invia ordine'}
                </Button>
                {loading &&
                    <Typography
                        textAlign={'center'}
                        style={{ color: 'lightgrey', letterSpacing: 1, marginTop: 5 }}
                        variant="subtitle1"
                    >
                        Stiamo elaborando il tuo pagamento...
                    </Typography>
                }
                {loading &&
                    <Typography
                        textAlign={'center'}
                        style={{ letterSpacing: 1, marginTop: 5 }}
                        variant="subtitle1"
                        color='secondary'
                    >
                        Si prega di non chiudere questa pagina
                    </Typography>
                }

            </DialogContent>
        </Dialog>

    )
}

export default DialogPaymentStripe